import React from 'react';
import PropTypes from 'prop-types';
import { isAndroidDevice } from '@lib/Utils';
import parse from 'html-react-parser';
import { localizedString } from '@languages';
import { Message } from '../components';

/**
 * Error 500
 */
export const Error500 = (props) => {
  return (
    <Message {...props} title={localizedString('errors.FLOW_V2_ERROR_500_ALERT_TITLE')} issue>
      {parse(localizedString('errors.FLOW_V2_ERROR_500_ALERT_DESCRIPTION'))}
    </Message>
  );
};

/**
 * Error 400
 */
export const Error400 = (props) => {
  const { onRetryAgain = () => null, ...restProps } = props;

  return (
    <Message
      {...restProps}
      buttons={[
        {
          label: localizedString('tryAgain'),
          full: true,
          onClick: onRetryAgain
        }
      ]}
      title={localizedString('errors.FLOW_V2_ERROR_400_ALERT_TITLE')}
      issue
    >
      {parse(localizedString('errors.FLOW_V2_ERROR_400_ALERT_DESCRIPTION'))}
    </Message>
  );
};

Error400.propTypes = {
  onRetryAgain: PropTypes.func
};

/**
 * Connectivity issue
 */
export const InternetCut = (props) => {
  return (
    <Message
      {...props}
      buttons={[
        {
          label: localizedString('errors.FLOW_V2_CONNECTION_ERROR_ALERT_BUTTON'),
          shadow: true,
          onClick: () => document.location.reload()
        }
      ]}
      title={localizedString('connectionError')}
      issue
    >
      {parse(localizedString('checkInternetConnection'))}
    </Message>
  );
};

/**
 * Timeout
 */
export const Timeout = (props) => {
  return (
    <Message {...props} title={localizedString('oopsSessionExpired')} issue>
      {localizedString('pleaseWait')}
    </Message>
  );
};

/**
 * Device Exceptions
 * */

export const DeviceIncompatibleOpenChrome = ({ issue = true, ...restOfProps }) => {
  return (
    <Message
      {...restOfProps}
      title={
        isAndroidDevice()
          ? localizedString('errors.FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_TITLE_ANDROID')
          : localizedString('app.FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_TITLE_IOS')
      }
      issue={issue}
    >
      {parse(
        isAndroidDevice()
          ? localizedString('errors.FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_DESCRIPTION_ANDROID')
          : localizedString('app.FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_DESCRIPTION_IOS')
      )}
    </Message>
  );
};

DeviceIncompatibleOpenChrome.propTypes = {
  issue: PropTypes.bool
};

/**
 * Locked Transaction URL
 */
export const LockedTransactionUrl = (props) => {
  const { transactionUrlLockExpiry } = props;

  return (
    <Message
      {...props}
      title={localizedString('lockedTransactionUrl')}
      issue={localizedString('identityCaptureInProgress')}
    >
      <div>
        {localizedString('verifyYouDontHaveOtherOpenTabs')}
        <br />
        {localizedString('pleaseCloseOtherTabsAndTryAgainAtXMinutes', transactionUrlLockExpiry)}
      </div>
    </Message>
  );
};

LockedTransactionUrl.propTypes = {
  transactionUrlLockExpiry: PropTypes.number
};
