import PropTypes from 'prop-types';
import classNames from 'classnames';
import { localizedString } from '@languages';
import { useLogo } from '@js/hooks/useLogo';
import classes from './NoMobileDeviceWrapper.style.module.scss';

export const NoMobileDeviceWrapper = ({
  title = localizedString('mobileIDVerification.title'),
  children
}) => {
  const finalLogo = useLogo();

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <img alt="" className={classNames(classes.logo, 'b-logo')} src={finalLogo} />
        <div className={classes.block}>
          <div className={classes.title}>{title}</div>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

NoMobileDeviceWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};
