import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Page from '@lib/components/v2/Page';

import { localizedString } from '@languages';
import classes from './Message.style.module.scss';

const Message = ({
  title,
  children,
  landscape,
  dark,
  issue,
  completed,
  buttons,
  alignCenterVertical = true
}) => {
  const {
    FLOW_V2_EXCEPTION_ALIGN_CENTER = true,
    FLOW_V2_EXCEPTION_SHOW_ICON = true,
    FLOW_V2_EXCEPTION_ISSUE_ICON = 'images/icons/png/issue-videoid.png'
  } = process.env;

  let contentClassName = classNames(classes.content);
  let headingClassName = classNames(classes.heading);
  let descriptionClassName = classNames(classes.description);
  if (alignCenterVertical && FLOW_V2_EXCEPTION_ALIGN_CENTER) {
    contentClassName = classNames(classes.content, classes.content_center_vertical);
    headingClassName = classNames(classes.heading, classes.heading_text_align_center);
    descriptionClassName = classNames(classes.description_text_align_center);
  }

  return (
    <Page
      buttons={buttons}
      className={classNames(classes.wrapper)}
      isDark={landscape || dark}
      isMessage
    >
      <div className={contentClassName}>
        {landscape && (
          <div className={classes.landscape}>
            <div className={classNames(classes.content, { 'text-white': dark || landscape })}>
              {localizedString('pleaseRotateYourDeviceToPortrait')}
              <img alt="" src="images/icons/png/landscope.png" />
            </div>
          </div>
        )}
        {FLOW_V2_EXCEPTION_SHOW_ICON && issue && (
          <div className={classes.issue} data-testid="message-issue-icon">
            <img className={classes['img-icon']} alt="" src={FLOW_V2_EXCEPTION_ISSUE_ICON} />
          </div>
        )}
        {FLOW_V2_EXCEPTION_SHOW_ICON && completed && (
          <div className={classes.issue}>
            <img className={classes['img-icon']} alt="" src="images/icons/svg/icon_completed.svg" />
          </div>
        )}
        {!landscape && title && (
          <div className={headingClassName} data-testid="message-title">
            {title}
          </div>
        )}
        {!landscape && (
          <div className={descriptionClassName} data-testid="message-content">
            {children}
          </div>
        )}
      </div>
    </Page>
  );
};

Message.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      shadow: PropTypes.bool,
      large: PropTypes.bool
    })
  ),
  issue: PropTypes.bool,
  completed: PropTypes.bool,
  dark: PropTypes.bool,
  landscape: PropTypes.bool,
  alignCenterVertical: PropTypes.bool
};

Message.defaultProps = {
  title: '',
  issue: false,
  completed: false,
  dark: false,
  landscape: false
};

export default Message;
