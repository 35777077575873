const DEFAULT_ELIGIBLE_AGE = 18;

export const isAgeEligible = (age, eligibleAge = DEFAULT_ELIGIBLE_AGE) => {
  return age >= eligibleAge;
};

export const selectEligibleAge = (state) => {
  const { ELIGIBLE_AGE = DEFAULT_ELIGIBLE_AGE } = process.env;
  const perChannelConfig = parseInt(state?.appConfig?.ageAcceptance, 10);

  if (!perChannelConfig) {
    return ELIGIBLE_AGE;
  }

  return perChannelConfig;
};
