/* eslint-disable camelcase */
import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isArray from 'lodash/isArray';

import Address from '@services/Address';
import { Validation } from 'calidation';
import { localizedString } from '@languages';
import { getResAddressValue } from '@lib/Utils';
import { getCountryLabelFromIso2Code, getStatesFromCountry } from '@lib/countryUtils';
import { Input, Select, CountrySelect } from '@lib/components/v2/Form';
import { ADDRESS_SEARCH_DEBOUNCED_DELAY_IN_MS } from '@lib/constants/addressSearchDebouncedDelay';
import { short, detailed } from './AddressFinder.form';
import classes from './AddressFinder.style.module.scss';

export default class AddressFinder extends Component {
  static propTypes = {
    inputType: PropTypes.string,
    data: PropTypes.object,
    onChange: PropTypes.func,
    onShowDetailed: PropTypes.func,
    countryCodeIso2ToVerify: PropTypes.string,
    dataTestId: PropTypes.string,
    checkConfirm: PropTypes.bool,
    availableManualCountryCodesIso3: PropTypes.array,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    inputType: 'text',
    data: {},
    onChange: () => null,
    onShowDetailed: () => null
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState(props);

    this.handleChange = debounce(
      this.handleChange.bind(this),
      ADDRESS_SEARCH_DEBOUNCED_DELAY_IN_MS
    );

    this.handleSelect = this.handleSelect.bind(this);
    this.addressListPanelBottomRef = null;
  }

  getInitialState(props) {
    const {
      homeAddress,
      addressLine1,
      addressLine2,
      street_number,
      street_name,
      suburb,
      postcode,
      state_territory,
      isMatch,
      addressApiCalls
    } = props.data || {};

    return {
      homeAddress,
      address: homeAddress,
      addresses: {
        address: null
      },
      isMatch: props.countryCodeIso2ToVerify === 'OTHER' ? false : isMatch,
      selectedManualCountryCode: props.countryCodeIso2ToVerify,
      detailedAddress: {
        addressLine1,
        addressLine2,
        street_number,
        street_name,
        suburb,
        postcode,
        state_territory,
        country: getCountryLabelFromIso2Code(props.countryCodeIso2ToVerify)
      },
      filteredCountries: [],
      showDetailed: props.showDetailed,
      checkConfirm: props.checkConfirm,
      addressApiCalls,
      loading: false
    };
  }

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { countryCodeIso2ToVerify } = this.props;
    const isMatch = countryCodeIso2ToVerify === 'OTHER' ? false : nextProps.data.isMatch;
    this.setState({
      isMatch,
      checkConfirm: nextProps.checkConfirm
    });

    if (countryCodeIso2ToVerify !== nextProps.countryCodeIso2ToVerify) {
      const apiDisabled = await Address.isAddressAPIDisabled(nextProps.countryCodeIso2ToVerify);
      this.setState({
        apiDisabled
      });
    }
  }

  /**
   * Handle the change of detailed address in case match failed
   */
  handleChangeAddress = (detailedAddress) => {
    const { addressApiCalls } = this.state;
    const data = {
      ...detailedAddress,
      homeAddress: '',
      isMatch: false,
      addressApiCalls
    };

    this.props.onChange(data);
    this.setState({
      detailedAddress
    });
  };

  /**
   * Handle the change of the address
   * @param id
   * @param  {String} value
   * @return {Void}
   */
  handleChange(id, value) {
    const { countryCodeIso2ToVerify, onChange } = this.props;
    const { addressApiCalls } = this.state;
    let addressApiCallsN = addressApiCalls;

    this.setState({ loading: true });

    Address.find(value, countryCodeIso2ToVerify).then(async (addresses) => {
      ++addressApiCallsN;
      this.setState({
        addressApiCalls: addressApiCallsN
      });

      const apiDisabled = await Address.isAddressAPIDisabled(countryCodeIso2ToVerify);
      const { detailedAddress } = this.state;
      const data = {
        ...detailedAddress,
        homeAddress: value,
        isMatch: false,
        addressApiCalls: addressApiCallsN,
        apiDisabled
      };

      this.setState({
        loading: false,
        addresses: {
          [id]: addresses,
          detailedAddress,
          apiDisabled: data.apiDisabled
        }
      });

      if (this.addressListPanelBottomRef) {
        this.addressListPanelBottomRef.scrollIntoView({ behavior: 'smooth' });
      }
      onChange(data);
    });
  }

  async handleSelect(id, address, globalAddressKey) {
    const { addressApiCalls } = this.state;
    const { onChange, countryCodeIso2ToVerify } = this.props;
    let data = {
      homeAddress: address,
      isMatch: true,
      addressApiCalls
    };

    if (id === 'address') {
      if (globalAddressKey) {
        const { fullAddress, ...detailedAddress } = await Address.verify(
          null,
          countryCodeIso2ToVerify,
          null,
          globalAddressKey
        );

        this.setState(({ addresses }) => ({
          homeAddress: address,
          addresses: { ...addresses, address: null },
          detailedAddress
        }));
        data.homeAddress = address;
        data.addressApiCalls += 1;

        data = {
          ...data,
          ...omit(detailedAddress, ['addressData']),
          ...(detailedAddress.addressData || {})
        };
      } else {
        const detailedAddress = await Address.verify(address, countryCodeIso2ToVerify);
        this.setState(({ addresses }) => ({
          homeAddress: address,
          addresses: { ...addresses, address: null },
          detailedAddress
        }));

        data.homeAddress = address;
        data.addressApiCalls += 1;

        Object.keys(detailedAddress).forEach((key) => {
          data[key] = detailedAddress[key];
        });

        data = {
          ...data,
          ...omit(detailedAddress, ['addressData']),
          ...(detailedAddress.addressData || {})
        };
      }
    }

    onChange(data);
  }

  showManualFields = (e) => {
    e.preventDefault();
    this.setState({
      showDetailed: true
    });

    this.props.onShowDetailed(true);
  };

  generateAddressFields() {
    const { availableManualCountryCodesIso3 } = this.props;
    const { detailedAddress, selectedManualCountryCode } = this.state;

    const states = getStatesFromCountry(selectedManualCountryCode);

    const country = {
      value: selectedManualCountryCode,
      label: getCountryLabelFromIso2Code(selectedManualCountryCode)
    };

    return (
      <Validation initialValues={detailedAddress} config={detailed}>
        {({ dirty, errors, setField, submitted }) => (
          <div className={classes.detailedWrapper}>
            <div className={classNames(classes.countries)}>
              <CountrySelect
                filter={availableManualCountryCodesIso3}
                value={country}
                onChange={({ value, label }) => {
                  this.setState({ selectedManualCountryCode: value });
                  const detailedAddr = { ...detailedAddress };
                  detailedAddr.country = label;
                  detailedAddr.selectedManualCountryCode = value;
                  this.handleChangeAddress(detailedAddr);
                  setField({ country: label });
                }}
              />
            </div>
            <Input
              type="text"
              id="addressLine1"
              placeholder={localizedString('addressLine1')}
              className={classNames(classes.input)}
              hasError={dirty.addressLine1 || submitted ? errors.addressLine1 : null}
              onChange={(value) => {
                const detailedAddr = { ...detailedAddress };
                detailedAddr.addressLine1 = value;
                this.handleChangeAddress(detailedAddr);
                setField({ addressLine1: value });
              }}
              value={detailedAddress.addressLine1 || ''}
            />
            <Input
              type="text"
              id="addressLine2"
              placeholder={localizedString('addressLine2')}
              className={classNames(classes.input)}
              hasError={dirty.addressLine2 || submitted ? errors.addressLine2 : null}
              onChange={(value) => {
                const detailedAddr = { ...detailedAddress };
                detailedAddr.addressLine2 = value;
                this.handleChangeAddress(detailedAddr);
                setField({ addressLine2: value });
              }}
              value={detailedAddress.addressLine2 || ''}
            />
            <Input
              type="text"
              id="suburb"
              placeholder={localizedString('suburbOrCity')}
              className={classNames(classes.input)}
              hasError={dirty.suburb || submitted ? errors.suburb : null}
              onChange={(value) => {
                const detailedAddr = { ...detailedAddress };
                detailedAddr.suburb = value;
                this.handleChangeAddress(detailedAddr);
                setField({ suburb: value });
              }}
              value={detailedAddress.suburb || ''}
            />
            <div className={classes.inputGroup}>
              <Input
                type="text"
                id="postcode"
                placeholder={localizedString('postcode')}
                autocomplete="none"
                hasError={dirty.postcode || submitted ? errors.postcode : null}
                onChange={(value) => {
                  const detailedAddr = { ...detailedAddress };
                  detailedAddr.postcode = value;
                  this.handleChangeAddress(detailedAddr);
                  setField({ postcode: value });
                }}
                value={detailedAddress.postcode || ''}
              />
              {selectedManualCountryCode === 'AU' && (
                <div style={{ position: 'relative' }}>
                  <Select
                    id="state_territory"
                    borderBottomOnly
                    placeholder={localizedString('state')}
                    hasError={dirty.state_territory || submitted ? errors.state_territory : null}
                    options={states}
                    onChange={(state) => {
                      const detailedAddr = { ...detailedAddress };
                      detailedAddr.state_territory = state.value;
                      this.handleChangeAddress(detailedAddr);
                      setField({ state_territory: state.value });
                    }}
                    value={
                      detailedAddress.state_territory
                        ? {
                            label: detailedAddress.state_territory,
                            value: detailedAddress.state_territory
                          }
                        : null
                    }
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </Validation>
    );
  }

  render() {
    const { inputType, dataTestId, data, onChange, disabled } = this.props;
    const {
      addresses,
      showDetailed,
      isMatch,
      checkConfirm,
      apiDisabled,
      loading,
      detailedAddress,
      addressApiCalls
    } = this.state;

    const { ENABLE_ENTER_ADDRESS_MANUALLY = true } = process.env;

    const addressesList = (id, data) => {
      if (loading) {
        return (
          <ul className={classes.addresses}>
            <li className={classes.loading}>Loading...</li>
          </ul>
        );
      }
      if (isArray(data)) {
        return (
          <ul className={classes.addresses}>
            {data.map(({ a, full_address, text, global_address_key = null }, index) => (
              <li
                onClick={() => this.handleSelect(id, a || full_address || text, global_address_key)}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <span>
                  <img width="18" alt="" src="images/icons/png/map-pointer-videoid.png" />
                </span>{' '}
                {a || full_address || text}
              </li>
            ))}
            {ENABLE_ENTER_ADDRESS_MANUALLY && (
              <li className="text-right">
                <span onClick={this.showManualFields} className="font-weight-bold pt-2">
                  {localizedString('enterManually')}{' '}
                </span>{' '}
                <span>
                  <img width="18" alt="" src="images/icons/png/forward-videoid.png" />
                </span>
              </li>
            )}
          </ul>
        );
      }
      return null;
    };

    let { homeAddress } = this.state;

    if (!homeAddress) {
      const { addressLine1, postcode, streetName, streetType, streetNumber, suburb, state } = data;

      homeAddress = getResAddressValue({
        addressLine1,
        street_number: streetNumber,
        street_name: streetName ? `${streetName} ${streetType}` : null,
        suburb,
        postcode,
        state_territory: state
      });
    }

    return (
      <div className={classNames(classes.wrapper)}>
        {!showDetailed && (
          <div className={classes.inputWrapper}>
            <Validation config={short} initialValues={{ homeAddress }}>
              {({ dirty, errors, setField, submitted }) => {
                if (checkConfirm && (!homeAddress || homeAddress.length < 1)) {
                  // eslint-disable-next-line no-param-reassign
                  dirty.homeAddress = true;
                }
                let addressError = dirty.homeAddress || submitted ? errors.homeAddress : null;
                if (!showDetailed && !apiDisabled && isMatch !== true && dirty.homeAddress) {
                  addressError = localizedString('enterValidAddress');
                }

                return (
                  <Input
                    type={inputType}
                    id="homeAddress"
                    required
                    label={localizedString('residentalAddress')}
                    placeholder={localizedString(
                      'addressFinder.FLOW_V2_VERIFY_DETAILS_ADDRESS_PLACEHOLDER'
                    )}
                    hasError={addressError}
                    onChange={(value) => {
                      this.setState({ homeAddress: value });
                      setField({ homeAddress: value });

                      // This make the submit button change/disable inmediatly after typing
                      onChange({
                        ...detailedAddress,
                        homeAddress: value,
                        isMatch: false,
                        addressApiCalls,
                        apiDisabled
                      });

                      if (value && value.trim() !== (homeAddress || '').trim()) {
                        this.handleChange('address', value);
                      }
                    }}
                    value={homeAddress}
                    className={classes.inputElement}
                    dataTestId={dataTestId}
                    disabled={disabled}
                  />
                );
              }}
            </Validation>
            <span className={classes.icon}>
              <img alt="" src="images/icons/png/search.png" />
            </span>
            {addressesList('address', addresses.address)}
            <div
              ref={(ref) => {
                this.addressListPanelBottomRef = ref;
              }}
            />
          </div>
        )}
        {showDetailed && this.generateAddressFields()}
      </div>
    );
  }
}
